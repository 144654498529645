<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      title="View Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="displayRecord"
    />
  </div>
</template>

<script>
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  name: "CellRendererActions",
  components: {
    vSelect,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    displayRecord() {
      this.$router
        .push({
          name: "enquiry-viewer-view",
          params: {
            applicationId: this.$route.params.applicationId,
            webReference: this.params.data.webReference,
          },
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};
</script>
