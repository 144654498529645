<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button v-show="showSearchButton" color="primary" type="filled" class="float-right" icon-pack="feather" icon="icon-search" @click="getSearch"></vs-button>
            <vs-input
              class="
                float-right
                sm:mr-1
                mr-0
                sm:w-auto
                w-1/2
                sm:order-normal
                order-3
                sm:mt-0
                mt-2
              "
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search Web Ref..."
            />
          </div>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="enquiryViewerData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
          @grid-size-changed="onGridSizeChanged"
        >
        </ag-grid-vue>
        <vs-pagination :total="totalPages" :max="5" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActions from "./cell-renderers/CellRendererActions.vue";
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import enquiryViewerStore from "./enquiryViewerStore";
import helper from "./enquiryViewerHelper";
import Vue from "vue";
import TravioAccessControl from "../../components/travio-pro/TravioAccessControl.vue";

export default {
  components: {
    AgGridVue,  
    CellRendererActions,
    TravioAccessControl,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      dummyData: "",
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {        
        CellRendererActions,
      },
      searchQuery: "",
      showSearchButton:false,
      showDeletePriceRulePopup: false,
      selectedReplacementPriceRule: {},
    };
  },
  computed: {
    enquiryViewerData() {
      return this.$store.state.enquiryViewerStore.enquiryViewer;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    preferedDateFormat() {      
      return this.$store.state.AppActiveUser.dateFormat;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    }
  },
  created() {
    if (!enquiryViewerStore.isRegistered) {
      this.$store.registerModule("enquiryViewerStore", enquiryViewerStore);
      enquiryViewerStore.isRegistered = true;
    }
    //#region fetch required data from api and store in state
    this.$vs.loading();
    this.$store
      .dispatch(
        "enquiryViewerStore/fetchEnquiryViewer",
        this.$route.params.applicationId
      )
      .catch((err) => console.error(err))
      .finally(() => this.$vs.loading.close());
    //#endregion
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      { field: "searchId", hide: true },
      {
        headerName: "Actions",
        width: 150,
        sortable: false,
        suppressSizeToFit: false,
        cellRendererFramework: Vue.extend(CellRendererActions),
      },
      {
        headerName: "Search Id",
        field: "searchId",
        sortable: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Web Reference",
        field: "webReference",
        sortable: true,        
        suppressSizeToFit: false,
      },
      {
        headerName: "Search Type",
        field: "searchType",
        sortable: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Passengers",
        field: "passengers",
        sortable: false,
        valueFormatter: this.activeFormatter,
        suppressSizeToFit: false,
      },
      // Supppress size to fit excludes column from auto resize if gridApi.sizeColumnsToFit() is called
      {
        headerName: "Search Date",
        field: "searchDate",
        suppressSizeToFit: false,
        valueFormatter: this.dateFormatter,
      },
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    dateFormatter(date) {      
      return helper.dateFormatter.date(date.value,this.preferedDateFormat);
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
     onGridSizeChanged(params) {
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    },
    onGridReady(params) {
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    getSearch(){
      this.$router
        .push({
          name: "enquiry-viewer-view",
          params: {
            applicationId: this.$route.params.applicationId,
            webReference: this.searchQuery,
          },
        })
    },
  },
   watch: {
    "searchQuery": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if(this.gridApi){
          //gives the no of records currently shown in the grid
          const rowCount = this.gridApi.getDisplayedRowCount();
          if(rowCount > 0){
            this.showSearchButton = false;
          }
          else{
            this.showSearchButton = true;
          }
          //console.log("row is watch " + this.gridApi.getDisplayedRowCount());
        }
      },
    }
   }
};
</script>

<style>
</style>
